import ru from '@/i18n/ru.json'
import uz from '@/i18n/uz.json'
import en from '@/i18n/en.json'

export const defaultLocale = 'ru'

export const languages = {
    ru,
    uz,
    en
}